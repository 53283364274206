
const page = {
  state: {
    homePage: '0',
    isMobile: 'pc端',
    maodian: '',
  },

  mutations: {
    SET_HOME_PAGE: (state, num) => {
      state.homePage = num
    },
    SET_IS_MOBIE: (state, moblie) => {
      state.isMobile = moblie
    },
    SET_MAODIAN: (state, maodian) => {
      state.maodian = maodian
    },
  },

  actions: {
    // 获取模块信息
    GetHomePager({ commit }, id) {
        return new Promise((resolve, reject) => {
          if (id) {
            commit('SET_HOME_PAGE', id);
            resolve()
          } else {
            reject(error)
          }
        })
    },
    // 存储是pc端还是移动端判断
    GetIsMobile({ commit }, value) {
      return new Promise((resolve, reject) => {
        if (value) {
          commit('SET_IS_MOBIE', value);
          resolve()
        } else {
          reject(error)
        }
      })
    },
    // 存储移动端锚点
    GetMaodian({ commit }, value) {
      return new Promise((resolve, reject) => {
        if (value) {
          commit('SET_MAODIAN', value);
          resolve()
        } else {
          reject(error)
        }
      })
    },
  }
}

export default page
