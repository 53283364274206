import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/index',
    name: 'Home',
    component: () => import('../views/HomePc.vue')
  },
  {
    path: '/h5',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/case',
    name: 'case',
    component: () => import('../views/Case.vue')
  },
  {
    path: '/casedetails/:id',
    name: 'casedetails',
    component: () => import('../views/CaseDetails.vue')
  },
  {
    path: '/goin',
    name: 'goin',
    component: () => import('../views/GoIn.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 判断是否需要登录权限 以及是否登录
// router.beforeEach((to, from, next) => {
//   // 判断是否需要登录权限
//   if (to.matched.some(res => res.meta.requireAuth)) {
//     // 判断是否登录
//     if (sessionStorage.getItem('token')) {
//       next()
//     } else {
//       // 没登录则跳转到登录界面
//       next({
//         path: '/login',
//         query: {
//           redirect: to.fullPath
//         }
//       })
//     }
//   } else {
//     next()
//   }
// })

export default router
