<template>
  <div id="app">
    <el-container>
      <!-- pc端导航 -->
      <el-header v-if="isMobile === 'pc端'" class="pc_header">
        <div class="logo">
          <img src="./assets/images/logo.png" alt />
        </div>
        <el-tabs v-model="activeName" @tab-click="handleSelect" class="table_sty">
          <el-tab-pane label="首页" name="0"></el-tab-pane>
          <el-tab-pane label="关于我们" name="1"></el-tab-pane>
          <el-tab-pane label="产品" name="2"></el-tab-pane>
          <el-tab-pane label="服务" name="3"></el-tab-pane>
          <el-tab-pane label="合作伙伴" name="4"></el-tab-pane>
          <el-tab-pane label="新闻" name="/case"></el-tab-pane>
          <!-- <el-tab-pane label="合作伙伴" name="/goin"></el-tab-pane> -->
        </el-tabs>
      </el-header>
      <!-- 移动端导航 -->
      <el-header v-if="isMobile === '移动端'" class="yd_header">
        <div class="logo">
          <img src="./assets/images/logo.png" alt />
        </div>
        <div class="back-top">
          <el-dropdown trigger="click" @command="handleCommand">
            <el-button tplain class="btutton_sty">
              <i class="el-icon-s-operation"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown" class="dropdown_sty">
              <el-dropdown-item command="#home">首页</el-dropdown-item>
              <el-dropdown-item command="#abous">关于我们</el-dropdown-item>
              <el-dropdown-item command="#product">产品</el-dropdown-item>
              <el-dropdown-item command="#service">服务</el-dropdown-item>
              <el-dropdown-item command="#partner">合作伙伴</el-dropdown-item>
              <el-dropdown-item command="/case">新闻</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>

      <!-- 内容 -->
      <el-main>
        <div class="con_height"></div>
        <router-view />
      </el-main>
      <!-- pc底部 -->
      <div class="footer_pc" v-show="isShow && isMobile == 'pc端'">
        <div class="footer-content">
          <div class="logo">
            <img src="./assets/images/logo2.png" alt />
            <p style="display: flex;align-items: center;">
            <span style="position: relative;width:1.2rem;padding-top: 1.2rem;margin-right:5px">
              <img src="@/assets/images/beian-gov.png" alt="" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;padding:0px">
            </span>
              <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32020602001674" style="color: #d4edff;"
                target="_blank">苏公网安备32020602001674</a>
            </p>
            <p><a href="https://beian.miit.gov.cn/" style="color: #d4edff;" target="_blank">苏ICP备2022049255号-1</a></p>
          </div>
          <ul class="content-nav">
            <li>
              <p @click="handleSelect({ name: '0' })" class="pointer">首页</p>
            </li>
            <li>
              <p @click="handleSelect({ name: '1' })" class="pointer">关于我们</p>
            </li>
            <li>
              <p @click="handleSelect({ name: '2' })" class="pointer">产品</p>
            </li>
            <li>
              <p @click="handleSelect({ name: '3' })" class="pointer">服务</p>
            </li>
            <li>
              <p @click="handleSelect({ name: '4' })" class="pointer">合作伙伴</p>
            </li>
            <li>
              <p @click="handleSelect({ name: '/case' })" class="pointer">新闻</p>
            </li>
            <li>
              <p>联系我们</p>
              <!-- <span>邮箱：chaoliu@superfluidity.com.cn</span> -->
              <span>联系人：姜女士</span>
              <span>联系电话：19965806910</span>
              <span>地址：无锡市惠山区生科路10-2号</span>
            </li>
          </ul>
        </div>

      </div>

      <!-- 移动端底部 -->
      <div class="footer" v-show="isMobile == '移动端'">
        <div class="footer-content">
          <div class="logo">
            <img src="./assets/images/logo2.png" alt />
            <p style="display: flex;align-items: center;">
            <span style="position: relative;width:1.2rem;padding-top: 1.2rem;">
              <img src="@/assets/images/beian-gov.png" alt="" style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;padding:0px">
            </span>
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32020602001674"
              target="_blank">苏公网安备32020602001674</a>
            </p>
            <p>备案号：<span>苏ICP备2022049255号-1</span></p>
          </div>
          <ul class="content-nav">
            <li>
              <p @click="handleCommand('#home')" class="pointer">首页</p>
            </li>
            <li>
              <p @click="handleCommand('#abous')" class="pointer">关于我们</p>
            </li>
            <li>
              <p @click="handleCommand('#product')" class="pointer">产品</p>
            </li>
            <li>
              <p @click="handleCommand('#service')" class="pointer">服务</p>
            </li>
            <li>
              <p @click="handleCommand('#partner')" class="pointer">合作伙伴</p>
            </li>
            <li>
              <p @click="handleCommand('/case')" class="pointer">新闻</p>
            </li>
            <li>
              <p>联系我们</p>
              <!-- <span>邮箱：chaoliu@superfluidity.com.cn</span> -->
              <span>联系人：姜女士</span>
              <span>联系电话：19965806910</span>
              <span>地址：无锡市惠山区生科路10-2号</span>
            </li>
          </ul>
        </div>

      </div>
    </el-container>
  </div>
</template>

<script>
import store from '@/store'
export default {
  data() {
    return {
      router: true,
      isShow: false,

      activeName: '',
      isMobile: 'pc端',
    };
  },
  computed: {
    homePage() {
      return this.$store.state.page.homePage
    },
  },
  watch: {
    homePage(newData, oldData) {
      this.activeName = newData;
    }
  },
  mounted() {
    // 根据不同路由跳转不同页面
    if (this.onIsMobile()) {
      this.isMobile = '移动端';
      store.dispatch('GetIsMobile', '移动端').then(() => {
        this.$router.push('/h5');
      })
    } else {
      this.isMobile = 'pc端';
      store.dispatch('GetIsMobile', 'pc端').then(() => {
        this.$router.push('/index');
        this.activeName = '0';
      })
    }
  },
  methods: {
    // 判断是否是手机端，如果是，返回true
    onIsMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    // pc端点击导航
    handleSelect(key) {
      this.activeName = key.name;
      let url = key.name;
      switch (url) {
        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
          this.$router.push('/index')
          localStorage.setItem('locationUrl', '/index');
          this.isShow = false;
          // 调用vuex的更新模块参数
          store.dispatch('GetHomePager', url).then(() => {
            // console.log(this.homePage,'切换');
          })
          break;
        default:
          this.$router.push(url);
          localStorage.setItem('locationUrl', url);
          this.isShow = true;
      }
    },
    // 移动端点击导航
    handleCommand(command) {
      store.dispatch('GetMaodian', command).then(() => {
        if (command == '/case') {
          this.$router.push(command);
          localStorage.setItem('locationUrl', command);
        } else {
          this.$router.push('/h5');
          localStorage.setItem('locationUrl', '/h5');
        }
      })
    }
  },

};
</script>
<style lang="scss" scoped>
.table_sty {
  height: 100%;
}

.table_sty ::v-deep .el-tabs__item {
  height: 70px !important;
  line-height: 70px;
}

/*去掉tabs底部的下划线*/
.table_sty ::v-deep .el-tabs__nav-wrap::after {
  position: static !important;
}

.con_height {
  height: 70px;
}

// pc端导航栏
.el-container {
  flex-direction: column !important;
}

.pc_header {
  width: 100vw;
  height: 70px !important;
  margin: 0 auto;
  padding: 0 70px !important;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 200;
  -webkit-box-shadow: 0 0.03rem 0.06rem 0.01rem rgb(0 0 0 / 16%);
  box-shadow: 0 0.03rem 0.06rem 0.01rem rgb(0 0 0 / 16%);

  .logo {
    width: 150px;
    padding: 10px;

    img {
      width: 100%;
      height: 100%;
      line-height: 60px;
    }
  }
}

// 移动端导航栏
.yd_header {
  width: 100vw;
  height: 300px !important;
  padding-left: 70px !important;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 200;
  -webkit-box-shadow: 0 0.03rem 0.06rem 0.01rem rgb(0 0 0 / 16%);
  box-shadow: 0 20px 20px -20px #909399;

  .logo {
    height: 250px;
    padding-top: 50px;

    img {
      width: 700px;
      height: 200px;
      // line-height: 60px;
    }
  }
}

// 移动端顶部下拉菜单
.back-top {
  width: 400px;
  height: 240px;
  border-radius: 50px;
  margin-top: 45px;

  .el-dropdown {
    .btutton_sty {
      width: 400px;
      height: 200px;
      font-size: 140px;
    }
  }
}

.dropdown_sty {
  width: 100vh;
  top: 290px !important;
  left: 0 !important;

  li {
    height: 120px;
    line-height: 120px;
    font-size: 60px;
    padding: 0 4.25rem;
  }
}

// pc底部栏
.footer_pc {
  width: 100%;
  height: 216px;
  overflow: hidden;
  background-color: #14679f;

  .footer-content {
    width: 1240px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    .content-nav {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        //justify-content: center;
        align-items: flex-start;

        p {
          font-size: 20px;
          color: #d4edff;
          padding: 10px 0;
        }

        .pointer {
          cursor: pointer;
        }

        span {
          color: #f7f7f7;
          font-weight: 300;
          padding: 5px 0;
        }
      }
    }

    .logo {
      img {
        width: 171px;
        height: 53px;
        padding: 10px;
      }

      p {
        margin-left: 10px;
        color: #d4edff;

        span {
          color: #f7f7f7;
        }
      }
    }
  }
}

// 移动端底部栏
.footer {
  width: 100%;
  height: 650px;
  overflow: hidden;
  background-color: #14679f;

  .footer-content {
    width: 1800px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    .content-nav {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        //justify-content: center;
        align-items: flex-start;

        p {
          font-size: 20px;
          color: #d4edff;
          padding: 10px 0;
        }

        .pointer {
          cursor: pointer;
        }

        span {
          color: #f7f7f7;
          font-weight: 300;
          padding: 5px 0;
        }
      }
    }

    .logo {

      // width: 400px;
      img {
        width: 400px;
        height: 120px;
        padding: 10px;
      }

      p {
        margin-left: 10px;
        color: #d4edff;

        span {
          color: #f7f7f7;
        }

        a {
          color: #d4edff;
        }
      }
    }
  }
}
</style>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}

html,
body {
  height: 100%;
}

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.el-main {
  padding: 0 !important;
}

// 滚动条
::-webkit-scrollbar {
  width: 0 !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
</style>
