// 基准大小
const baseSize = 16
// 设置 rem 函数
function setRem () {
  // 当前页面宽度相对于 1920 宽的缩放比例，可根据自己需要修改。
  // const scale = document.documentElement.clientWidth / 1620
  // console.log(scale,'scale>>>>>>>>>>>>>>>>>>>>>',Math.min(scale, 2));
  // // 设置页面根节点字体大小
  // document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)) + 'px'




  let clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
  // 设计图尺寸是 750px，这样 *20 之后，1rem 就等于 20px;
  const fontSize = (clientWidth / 750 * 6);
  document.documentElement.style.fontSize = fontSize + "px";
  
}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}

